<template>
  <div class="list-content-page">
    <h6 class="ym-title">
      <el-link class="ym-title-link" href="#/listFowlOuts">出栏信息 <i class="el-icon-arrow-right"></i> </el-link>
      <span>{{ title }}</span>
    </h6>
    <!-- 条件筛选 -->
    <div class="ym-panel-general">
      <el-form
        class="fl"
        :inline="true"
        :model="form"
      >
        <el-form-item prop="afcNum" label="防伪码：">
          <el-input
            v-model="form.afcNum"
            size="medium"
            placeholder="全部"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button class="ym-input-left" type="primary" round size="medium" @click="search"
            >查询</el-button
          >
          <el-button plain round size="medium" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="ym-panel-table">

      <!--表格-->
      <el-table
        :data="tableData"
        v-loading="loading"
        highlight-current-row
        class="ym-table"
        size="mini"
        stripe
        border
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '0px' }"
      >
        <el-table-column
          type="index"
          label="序号"
          :index="indexMethod"
          width="60"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="afcNum"
          label="防伪码"
          min-width="200"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="qrcodeUrl"
          label="二维码"
          min-width="200"
          align="center"
        >
        <template slot-scope="scope">
          <el-popover
              placement="top"
              width="260"
              trigger="hover">
              <img :src="scope.row.qrcodeUrl" alt="" width="230">
              <el-avatar v-if="scope.row.qrcodeUrl" slot="reference" shape="square" :src="scope.row.qrcodeUrl"></el-avatar>
          </el-popover>
          </template>
        </el-table-column>

        <el-table-column
          prop="productName"
          label="产品名称"
          min-width="200"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="batchNum"
          label="产品批次编号"
          min-width="200"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="vervelMac"
          label="鸡脚环MAC"
          min-width="200"
          align="center"
        ></el-table-column>
  
      </el-table>
      <!-- 页码 -->
      <el-pagination
        v-if="page.total>=10"
        @size-change="pageSizeChange"
        @current-change="pageChange"
        :current-page="page.currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      title: '出栏明细列表',
      tableData: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 40
      },
      form: {
        afcNum: ''
      }
    }
  },
  computed: {
    id() {
      return this.$route.query.id
    },
    afcBatchId() {
      return this.$route.query.afcBatchId
    }
  },
  created() {
    if (this.id && this.afcBatchId) {
      this.getVervel()
    }
  },
  methods: {
    goBack() {
      this.$router.push('/listFowlOuts')
    },
    indexMethod(index) {
      if (index + 1 < 10) {
        return '0' + (index + 1)
      }
      return index + 1
    },
    // 条件搜索
    search() {
      this.getVervel()
    },
    // 条件重置
    reset() {
      this.form.afcNum = ''
      this.getVervel()
    },
    pageSizeChange(val) {
      this.page.pageSize = val
      this.getVervel()
    },
    pageChange(val) {
      this.page.currentPage = val
      this.getVervel()
    },
    async getVervel() {
      const params = {
        afcNum: this.form.afcNum,
        afcBatchId: this.afcBatchId,
        afcBatchOutId: this.id,
        enableFlag: 3,
        pageSize: this.page.pageSize,
        pageNum: this.page.currentPage
      }
      this.loading = true
      const { data } = await this.$http.fowlOutsInfor.listVervels(params)
      if (data.code !== '0') return this.$message.error(data.msg || '获取列表失败')
      // 回显表单
      console.log('查询数据：', data.data.records)
      this.tableData = data.data.records
      this.page.total = parseInt(data.data.total)
      this.page.currentPage = parseInt(data.data.current)
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scrope>
.list-content-page {
  .el-form-item {
    margin-bottom: 0;
  }
  
  .ym-table {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
  }

  //修改table样式
  .el-table .cell {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
    line-height: 23px;
    padding: 6px;
    display: flex;
    justify-content: center;
}
}
</style>
